import * as Styles from './styles';
import iconEye  from '../../assets/iconEye.svg';

export const VisibleEyesPassword = (props) => {
    const {handleVisibledPassword} = props

    return (
        <Styles.Container>
            <Styles.IconEyes src={iconEye} alt='ícone de um olho' onClick={() => handleVisibledPassword()} />
        </Styles.Container>
    )
}