import * as Style from "./styles"
import logo from "../../assets/mainLogoPage.svg"
export const Header = (props)=>{
    
        return(
            <Style.Container>
                <div className="logo-wrapper">
                    <img src={logo} alt='logo'/>
                </div>

                <div className="title">
                    <p>Seus resultados estão aqui!</p>
                </div>

                <div id={'horizontal-wrapper'} className="horizontal-select-wrapper">
                    {/* <div id='teste'> */}
                        <div className={'select-itens'} id={'general_result_button'} onClick={()=> props.getCoordenate('general_result')}>
                            <p>Geral</p>
                        </div>
                        
                        <div className={'select-itens'}id={'payment_form_button'} onClick={()=> props.getCoordenate('payment_form')}>
                            <p>Formas de pagamento</p>
                        </div>
                        
                        <div className={'select-itens'} id={'most_sold_button'} onClick={()=> props.getCoordenate('most_sold')} selected={true}>
                            <p>Mais vendidos</p>
                        </div>

                        <div className={'select-itens'} id={'bills_pay_button'} onClick={()=> props.getCoordenate('bills_pay')}>
                            <p>Contas a pagar</p>
                        </div>

                        <div className={'select-itens'} id={'bills_receive_button'} onClick={()=> props.getCoordenate('bills_receive')}>
                            <p>Contas a receber</p>
                        </div>

                    {/* </div> */}
                </div>
            </Style.Container>
        )
}