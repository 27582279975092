import * as Style from "./styles"
import topHeaderImg from '../../assets/imageHeader.svg'
import iconBillsToReceive from '../../assets/iconBillsToReceive.svg'
import iconGoBack from '../../assets/iconGoBack.svg'
import { useState } from "react";
import { Select } from "../Select";
import { Currency, FormateDate } from "../../global/functions";

export const BillsToReceiveModal = (props)=>{
    const[selectValue,setSelectValue] = useState("Próximos 7 dias")
    const[openFilter,setOpenFilter] = useState(false)


    const handleClickPeriod = (value)=>{
        switch (value) {
            case 1:
                props.getBillsToReceiveInfo("S","1")
                setSelectValue("Hoje")
                setOpenFilter(false)
            break;
            
            case 2:
                props.getBillsToReceiveInfo("S","2")
                setSelectValue("Próximos 7 dias")
                setOpenFilter(false)
            break;

            case 3:
                props.getBillsToReceiveInfo("S","3")
                setSelectValue("Próximos 30 dias")
                setOpenFilter(false)
            break;
        
            default:
                break;
        }
    }
    return(
        <Style.Container>
            <div onClick={()=>props.closeModal()} className="go-back">
                <Style.Icons width={'24px'} height={'24px'}>
                    <img src={iconGoBack} alt='ícone de voltar'/>
                </Style.Icons>
                <p className='title-card'>Contas a Receber</p>
            </div>
            <div className="main-content">

                <div className='generic-wrapper'>
                    <Style.Icons>
                        <img src={iconBillsToReceive} alt='ícone de seta azul'/>
                    </Style.Icons>
                </div>
                <div className='generic-wrapper'>
                    <Select
                        variant={0}
                        selectValue={selectValue}
                        handleClickPeriod={handleClickPeriod}
                        openFilter={openFilter}
                        OpenSelectOptions={setOpenFilter}
                    />
                </div>
                <div className='value-wrapper'>
                    <div className='generic-wrapper'>
                        <p className='text-bold'>{`Total a receber (${selectValue})`}</p>
                    </div>
                    <div className='generic-wrapper-values'>
                        <p className='value-general'>{props.completeList.length>0?Currency(props.completeList[0].total):"..."}</p>
                    </div>
                </div>
                <div className='items-list-wrapper'>
                    <div className="items-list-header">
                        <div className="items-list-header-left">
                            <p>Vencimento</p>
                        </div>
                        <div className="items-list-header-midle">
                            <p>Descrição</p>
                        </div>
                        <div className="items-list-header-right">
                            <p>Total $</p>
                        </div>
                    </div>
                    {
                        props.completeList.length>0?(
                            props.completeList[0].titulos.length > 0?(
                                props.completeList[0].titulos.map((item,index)=>{
                                    return(
                                        <div key={index} className="item-wrapper">
                                            <div className="item-content-left">
                                                <p>{FormateDate(item.dt_vencimento)}</p>
                                            </div>
                                            <div className="item-content-midle">
                                                <p>{item.descricao}</p>
                                            </div>
                                            <div className="item-content-right">
                                                <p>{Currency(item.total)}</p>
                                            </div>
                                        </div>        
                                    )
                                })

                            ):(
                                <div className="item-wrapper">
                                    <div className="item-content-left">
                                        <p>...</p>
                                    </div>
                                    <div className="item-content-midle">
                                        <p>...</p>
                                    </div>
                                    <div className="item-content-right">
                                        <p>...</p>
                                    </div>
                                </div>        

                            )
                        ):(
                        <div className="item-wrapper">
                            <div className="item-content-left">
                                <p>...</p>
                            </div>
                            <div className="item-content-midle">
                                <p>...</p>
                            </div>
                            <div className="item-content-right">
                                <p>...</p>
                            </div>
                        </div>        
                        )
                    }    
    
                    {/* <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>        
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>03/08</p>
                        </div>
                        <div className="item-content-midle">
                            <p>Contas de agua</p>
                        </div>
                        <div className="item-content-right">
                            <p>R$ 25.000,88</p>
                        </div>
                    </div>         */}
                </div>

                <div className='another-values-wrapper'>
                    <div className='top-header-image'>
                        <img src={topHeaderImg} alt='ícone de uma forma geométrica'/>
                    </div>
                    <div className='another-values-content'>
                        <div className='generic-wrapper'>
                            <p className='text-bold'>Totalizadores</p>
                        </div>
                        <div className='another-values-items'>
                            <p className='text-bold'>Hoje</p>
                            <p className='text-bold'>{props.billsToReceiveTotal.length>0?Currency(props.billsToReceiveTotal[0].hoje):"..."}</p>
                        </div>
                        <div className='another-values-items'>
                            <p className='text-default'>Vencidas</p>
                            <p className='text-default'>{props.billsToReceiveTotal.length>0?Currency(props.billsToReceiveTotal[0].vencidas):"..."}</p>
                        </div>
                        <div className='another-values-items'>
                            <p className='text-default'>Semana atual</p>
                            <p className='text-default'>{props.billsToReceiveTotal.length>0?Currency(props.billsToReceiveTotal[0].semana_atual):"..."}</p>
                        </div>
                        <div className='another-values-items'>
                            <p className='text-default'>Próxima semana</p>
                            <p className='text-default'>{props.billsToReceiveTotal.length>0?Currency(props.billsToReceiveTotal[0].proxima_semana):"..."}</p>
                        </div>
                        <div className='another-values-items-total'>
                            <p>Total a Receber</p>
                            <p>{props.billsToReceiveTotal.length>0?Currency(props.billsToReceiveTotal[0].total):"..."}</p>
                        </div>
                    </div>
            </div>
        </div>
        </Style.Container>
    )
}