import styled from "styled-components";
import { theme } from "../../global/theme";
import logoLogin from '../../assets/logoLogin.webp'


export const ContainerMain = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background: ${theme.background.White_2};
    position: relative;
    justify-content: center;
    overflow: hidden;    

`;

export const WrapperLogo = styled.div`
    display: flex;
    background-position: center;
    flex-direction: column;
    width: 75%;
    height: 100%;
    justify-content: flex-end;
    flex-shrink: 0;
    background-image: url(${logoLogin});
    background-size: contain;
    background-repeat: no-repeat;


    @media(max-width: 1600px){
        width: 60%;
    }

    @media(max-width: 996px){
        width: 50%;
    }

    @media(max-width: 740px){
        width: 50%;
    }

    @media(max-width: 740px){
        display: none;
    }
`;

export const WrapperLogin = styled.div`
    display: flex;
    height: 100%;
    width: 25%;
    padding: 80px 67px 45px 67px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: #F5F5F8;
    overflow-x: hidden;
    overflow-y: auto;

    @media(max-width: 1600px){
        width: 40%;
    }

    @media(max-width: 996px){
        width: 50%;
    }

    @media(max-width: 740px){
        width: 100%;
    }

    @media(max-width: 280px){
        width: 100%;
        padding: 80px 0 45px 0;
    }

`;

export const ContainerFields = styled.div`
    display: flex;
    width: 360px;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;

    @media(max-width: 740px){
        width: 100%;
    }
`;

export const ImagemLog = styled.img`
    width: 88px;
    height: 78.085px;
`;

export const TextLogin = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    color: ${theme.colors.Gray_7};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 20.48px;
`;


export const ContainerInputs = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    form{
        display: flex;
        flex-direction: column;
        width: 100%;


    }
`;

export const WrapperInput = styled.div`
    display: flex;
    width: 100%;
    padding: 0px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    input{
        display: flex;
        height: 48px;
        padding: 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: ${theme.borderRadius.average};
        border: 1px solid ${theme.colors.Gray_8};
        background: ${theme.background.White_2};
        color: ${theme.text.Black_1};
        font-size: 14px;
        font-weight: 400;
        line-height: 18px; 
    }
`;

export const WrapperLabelAndInputEmail = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`;

export const WrapperLabelAndInputPassword = styled.div`
    display: flex;
    flex-direction: column;
    align-items:start;
    gap: 8px;
    align-self: stretch;
    margin-top: 8px;
    position: relative;

    input{
        width: 100%;
        padding: 12px 16px;

    }

    .container-input-password{
        width: 100%;
        position: relative;
    }

`;

export const ContainerButton = styled.div`
    display: flex;
    width: 100%;
    padding: 0px 32px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    height: 48px;
    margin-top: 8px;

`;

export const Button = styled.button`
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 10px 0 0 0;
    width: 100%;
    height: 100%;
    background: ${theme.background.blue_2};
    border-radius: ${theme.borderRadius.average};
    color: ${theme.text.White_1};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    border: none;

`;

export const FooterLogin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: ${theme.text.Grey_6};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

`;

export const ContainerMessage = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

`;