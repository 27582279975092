import { verifyEventClickModal } from "../../global/functions"
import * as Style from "./styles"

export const Modal =(props)=>{
    const  HandleCliqueClose = (e) => { 
        if (props.id ===  e.target.id) {
            props.closeModal()
        } 
    } 

    return (
        <Style.Container id={props.id} 
        onMouseDown={(e) => verifyEventClickModal(e, HandleCliqueClose)} 
            onMouseUp={(e) => verifyEventClickModal(e, HandleCliqueClose)}>
            <Style.BoxModal>
                {props.children}
            </Style.BoxModal>
        </Style.Container>
    )
}