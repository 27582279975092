import { useEffect } from "react"
import { BillsToPayCard } from "../../components/BillsToPayCard"
import { BillsToReceive } from "../../components/BillsToReceive"
import { GeneralResultCard } from "../../components/GeneralResultsCard"
import { Header } from "../../components/header"
import { MostSoldProductsCard } from "../../components/MostSoldProductsCard"
import { PaymentFormCard } from "../../components/PaymentFormCard"
import * as Style from "./styles"

export const Dashboard = ()=>{

    let clearTimerScroll = 0;

    const removeScroll =   () => {
        let root = document.getElementById('root')
        clearInterval(clearTimerScroll);
        root.removeEventListener('scroll',scroll);
    };

    const addClass = (id) => {
        let  element = document.getElementById(`${id}_button`);
        removeClassButton(id);
        element.classList.add('focus');
    }

    const removeClassButton = (id) => {
        let  element;
        var items = document.querySelector(".horizontal-select-wrapper").childNodes;

        for (let i = 0; i <items.length ; i++) {
            if(id !== items[i].id){
                element = document.getElementById(items[i].id);
                element.classList.remove('focus');
            }
        }

    }

    const timer = () => {
        let root = document.getElementById('root')
        clearTimerScroll = setTimeout(()=> {
            root.addEventListener('scroll', scroll);
        },1000)
    }

    const scrollCategory = (id) => {
        addClass(id);
        let  a  = document.getElementById(`${id}_button`);
        let coord = a.getBoundingClientRect();
        focusScroll(coord);   
    }

    const focusScroll = (coord) =>{
        let b = document.getElementById('horizontal-wrapper');
        return b.scrollBy((coord.x) - 100 ,0);    
    }

    const getCoordenate = (id) =>{
        scrollSuaveDivRoot(id)
        removeScroll();
        timer();
        addClass(id);
        scrollCategory(id)
    }

    const scrollSuaveDivRoot = (id) => {
        let root = document.getElementById('root')
        let elementScroll = document.getElementById(id)
        let position;

        if (elementScroll !== null){
            position = elementScroll.getBoundingClientRect();
            root.scrollBy(0, position.y - 150)
        }
    }

    const scroll = () => {
        let idElment = 0;

     
        let lista  = document.querySelector('.content-wrapper');
        let root = document.getElementById('root')

        if(lista !== null){
            for (let index = 0; index < lista.childNodes.length; index++) {
                let elTopo = lista.childNodes[index].offsetTop; // distância relativa do elemento ao topo
    
                let scrollTopo = root.scrollTop; // scroll da janela
                let altJanela = root.clientHeight ; // altura da janela
                let distancia = ((elTopo - scrollTopo) + (altJanela - 180)) ; //distância absoluta do elemento ao topo
    
                if (distancia < altJanela  ){// verifico se o elemento apareceu na janela
                    idElment = lista.childNodes[index].id;
                    scrollCategory(idElment);
                }
            } 

        }
    }

    useEffect(()=>{
        let root = document.getElementById('root');
        root.addEventListener('scroll',scroll);

        return () => {
            clearInterval(clearTimerScroll);
        }

    },[])

    return(
        <Style.Container>
            <div className="header">
                <Header
                    getCoordenate={getCoordenate}
                />
            </div>

            <div className="content-wrapper">
                <GeneralResultCard id={'general_result'}/>
                <PaymentFormCard id={'payment_form'}/>
                <MostSoldProductsCard id={'most_sold'}/>
                <BillsToPayCard id={'bills_pay'}/>
                <BillsToReceive id={'bills_receive'}/>
            </div>
        </Style.Container>
    )
}