import * as Style from './styles';
import DashboardServices from '../../settings/Api/services/dashboard_services';
import { getToken } from '../../settings/Api/services/local_storage_services';
import { useEffect, useState } from 'react';
import { Currency, verifyUrlOnPopState } from '../../global/functions';
import { Modal } from '../Modal';
import { MostSoldProductsModal } from '../MostSoldProductsModal';
import { useLocation, useNavigate } from 'react-router-dom';

export const MostSoldProductsCard = (props) =>{
    
    const[topProductstResults,setTopProductsResults] = useState([])
    const[modalIsOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const getMostSoldProductstInfo = async ()=>{
        try {
            const service = new DashboardServices()
            const result = await service.getDashboardTop5SellsInfo({
                cnpj:getToken('CNPJ'),
                tipo:'1'
            })
            if(result.success === true){
                setTopProductsResults([result.data])
            }
            
        } catch (error) {
            
        }
                
    }

    const closeModal=()=> {
        setIsOpen(false);
        navigate(-1);
        window.removeEventListener('popstate',HandleButtonBack )
    }

    const handleClickSeeMore =()=>{
        navigate('/Dashboard/ProdutosMaisVendidos');
        setIsOpen(true);
    }

    useEffect(() => {
        verifyUrl();
        getMostSoldProductstInfo()
    },[]);

    useEffect(() => {
        HandleButtonBack();

    }, [location.pathname])
    
    const verifyUrl = () => {
        if(window.location.pathname  === '/Dashboard/ProdutosMaisVendidos') {
            setIsOpen(true);
            getMostSoldProductstInfo();
        }
        else if (window.location.pathname  === '/Dashboard') {
            setIsOpen(false);
        }
    }

    const HandleButtonBack = () => {
        verifyUrlOnPopState(verifyUrl);
    }

    return(
        <Style.Container id={props.id}>
            <div className='generic-wrapper'>
                <p className='title-card'>Produtos mais vendidos</p>
            </div>
            
            <div className='items-list-wrapper'>
                {
                    topProductstResults.length>0?(
                        topProductstResults[0].map((item,index)=> {
                            return(
                                <div key={index} className='item-general'>
                                    <div className='left-general-item-content'>
                                        <div className='icon-background'>
                                        <p>{item.sequencia}</p>
                                        </div>    
                                        <p>{item.produto}</p>
                                    </div>
                                    <div className='right-general-item-content'>
                                        <div className='qtd-sells'>
                                            <p>{item.qtd}</p>
                                        </div>
                                        <p className='item-general-value'>{Currency(item.valor)}</p>
                                    </div>
                                </div>
                            )
                        })

                    ):(
                        <div className='item-general'>
                            <div className='left-general-item-content'>
                                <div className='icon-background'>
                                    <p>...</p>
                                </div>    
                                <p>...</p>
                            </div>
                            <div className='right-general-item-content'>
                                <div className='qtd-sells'>
                                    <p>...</p>
                                </div>
                                <p className='item-general-value'>...</p>
                            </div>
                        </div>
                    )
                }
                {/* <div className='item-general'>
                    <div className='left-general-item-content'>
                        <div className='icon-background'>
                        <p>1</p>
                        </div>    
                        <p>chocolate</p>
                    </div>
                    <div className='right-general-item-content'>
                        <div className='qtd-sells'>
                            <p>9</p>
                        </div>
                        <p className='item-general-value'>{Currency(15)}</p>
                    </div>
                </div> */}
            </div>
            <div className='generic-wrapper'>
                <button onClick={()=>handleClickSeeMore()} className='see-more-button'>Ver mais +</button>
            </div>
            {modalIsOpen === true?
                (
                    <Modal id={'1'} closeModal={closeModal}>
                        <MostSoldProductsModal
                            closeModal={closeModal}
                        />
                    </Modal>
                
                ) 
                :(
                    null 
                )
            }
        </Style.Container>
    )
}