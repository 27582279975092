import styled from "styled-components";

export const Container = styled.div`    
    background-color: ${props=>props.theme.background.White_2};
    display: flex;
    flex-direction: column;
    gap:16px;
    padding: 24px 24px 0px 24px;

    ::-webkit-scrollbar{
        display: none;
        height: 0;
     
    }

    .logo-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title{
        display: flex;
        justify-content: center;
        color:${props=>props.theme.text.Grey_1};
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .horizontal-select-wrapper{
        display: flex;
        width: 100%;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        gap:8px;
        top:0;
        overflow-x: scroll;
        position: sticky;        
        margin-bottom: 15px;
    }

    .select-itens{
        cursor: pointer;
        display: flex;
        height: 32px;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        padding: 8px 14px;
        white-space: nowrap;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color:${props=>props.theme.text.Blue_2};
        background-color:${props=>props.theme.background.Blue_4};
        }
        .focus{
            background-color: ${props=>props.theme.background.Blue_1};
            color:${props=> props.theme.text.White_1}
        }

    #teste{
        display: flex;
        width: 100%;    
        /* justify-content: center; */
    }

`;
