import React from "react";
import {Row, Col, Table} from "react-bootstrap";
import {Container, Modal} from './styles'
import  { entrar } from '../../settings/Api/services/local_storage_services'
import { Link } from "react-router-dom";
import { verifyEventClickModal } from "../../global/functions"


export const Establishment = (props) => {

    const HandleCliqueClose = (e) => {
        if (e.target.id === props.id) props.onClose();
    }

    const HandleCliqueHref = (cnpj, url_servidor) => {
        entrar('CNPJ', cnpj); 
        entrar('SERVIDOR', url_servidor)
    }

    const RenderTable = ({DataResult}) => {
        return(
            <Table  hover >
                <thead style={{color:'#3B4758'}}>
                    <tr>
                        <th>Empresa</th>
                        <th>CNPJ</th>
                    </tr>
                    
                </thead>

                <tbody>
                
                    {

                        Object.keys(DataResult).reduce((item)=> {
                            return (
                                DataResult[item].map((item)=>
                                    <tr key={item.valor}>
                                        <td style={{textAlign:'left',paddingLeft:'40px'}}>{item.nome_empresa}</td>
                                        <td>
                                            <Link key={item.valor} className='btn btn-primary' style={{width:'146.75px'}}  onClick={() => HandleCliqueHref(item.valor, item.url_servidor)} to={'dashboard'}>
                                            {item.valor}
                                            </Link>
                                        </td>
                                    </tr>  
                                )

                            )


                        })

                    }
                
                </tbody>
          
          
        </Table>
    
        )
    
      }

    return (
        <Container id={props.id} 
            onMouseDown={(event) => verifyEventClickModal(event)}
            onMouseUp={(e) => verifyEventClickModal(e, HandleCliqueClose)}>
            <Modal >
                <Row id='Header'>
                    Empresas Cadastradas
                    <button  className="Close" onClick={()=> props.onClose()}></button>
                </Row>

                <Row >
                    <Col>
                        { props.DataResult.cnpj.length > 0 ? <RenderTable DataResult={props.DataResult}/> : null}
                    </Col>
                </Row>
            
            </Modal>
         
        </Container>
    )
}