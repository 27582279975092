import * as Style from "./styles"
import iconArrowSelect from "../../assets/iconArrowSelect.svg"
import iconArrowSelectBlue from "../../assets/iconArrowSelectBlue.svg"
export const Select = (props)=>{
    
    return(
        <Style.Container $variant={props.variant}>
            <div onClick={()=>props.OpenSelectOptions(!props.openFilter)}className="select-value-wrapper">
                <div className="left-content">
                    <p>{props.selectValue}</p>
                </div>

                <div className="right-content">
                    {
                        props.variant === 1?(
                            <Style.Icons width={'14px'} height={'14px'}>
                                <img src={iconArrowSelect} alt='ícone de uma seta'/>
                            </Style.Icons>

                        ):(
                            <Style.Icons width={'14px'} height={'14px'}>
                                <img src={iconArrowSelectBlue} alt='ícone de uma seta azul'/>
                            </Style.Icons>
                        )
                    }
                </div>
                
            </div>
            {
                props.openFilter?(
                    <Style.OptionsWrapper>
                        <div onClick={()=>props.handleClickPeriod(1)} className="option">Hoje</div>
                        <div onClick={()=>props.handleClickPeriod(2)} className="option-midle">Próximos 7 dias</div>
                        <div onClick={()=>props.handleClickPeriod(3)} className="option">Próximos 30 dias</div>
                    </Style.OptionsWrapper>
                ):(
                    null
                )
            }
        </Style.Container>
    )
}