import React from "react";
import { Navigate} from "react-router-dom";
import { isAuthenticated } from "./local_storage_services";

// jeito antigo
// const RouteWrapper = () => {
//     return (isAuthenticated('CNPJ') ? <Outlet/> :<Navigate to="/" />)

// }

const RouteWrapper = ({ children })  => {
    return (isAuthenticated('CNPJ') ? children :<Navigate to="/" />)
}
export default RouteWrapper;