import axios from "axios";
import { getToken } from "../services/local_storage_services";
import { incript } from "../services/md5_generate";

const baseApi = (baseURL) => {
    const api = axios.create({
        baseURL: baseURL
    });

    api.interceptors.request.use(async (config) => {
        const TokenKey = getToken('CNPJ');
        const  url = config.url;

        if (config.url.endsWith('cadastrarUsuario') || config.url.endsWith('login')) {
            const  url = config.url;
            let token = `Basic ${incript('20798514000141',url)}`;
            config.headers['Authorization'] = token; 
            
        }else{
            let token = `Basic ${incript(TokenKey,url)}`;
            config.headers['Authorization'] = token; 
        }  
        return config;
    })

    return api
}
export default baseApi;