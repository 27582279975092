import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    width: 20px;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    right: 12px;
    top: 0;
 
`;

export const IconEyes = styled.img`
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    cursor: pointer;
`;