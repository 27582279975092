export const theme = {
    colors:{
        Red_1:'#D40E0F',
        Black_1:"#30333C",
        Pink_1:'#FDE6E8',
        White:'#FFFFFF',
        Grey_1:'#898989',
        Grey_2:'#B5B5B6',
        Grey_3:'#ECECF0',
        Grey_4:'#DDE2EB',
        Grey_5:'#C6CDD4',
        Grey_6:'#F2F2F2',
        Gray_7:"#1D1D1D",
        Gray_8:"#BDBCC2",
        Blue_1:'#06024E',
        Blue_2:'#201F38',
        Blue_3: '#0B00E5',
        BLue_4: '#EBEAFF',
        Blue_5:'#001AFF',
    },

    text:{
        Blue_1:'#0019FF',
        Blue_2:'#001AFF',
        Grey_1:'#30333C',
        Grey_2:'#7F8F9F',
        Grey_3:'#C6CDD4',
        Grey_4:'#B5B5B6',
        Grey_5:'#C2C9D6',
        Grey_6: '#85858F',
        Primary:'#8F8585',
        Black_1:'#2F2F2F', 
        White_1:'#FFFFFF', 
        White_2:'#FFF', 
        Red_1:'#C51F2A',
        Red_2:'#D40E0E',
        Yellow_1:'#B19A28',
        Yellow_2:'#F5E7A3'

    },

    background:{
        Primary:'#F8F5F5',
        Blue_1:'#001AFF',
        blue_2: '#0B02C1',
        Blue_4:'#F1F0FF',
        Blue_5:'#F0F2F5',
        Red_1:'#C51F2A',
        White_2:'#FFF',
        Grey_1:'#E6E3E3',
        Grey_3:'#Eeecec',
        Black_1:'#2F2F2F',
        Grey_2:'#C2BCBD',
        Blue_rgb:'rgb(8, 75, 221)'

    },

    borderRadius:{
        button_small: '0.25rem',
        average: '10px'
    }
    

}