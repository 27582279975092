import styled from "styled-components";

export const Container = styled.div`
    min-width: 146px;
    width: auto;
    height: 28px;
    border-radius: 8px;
    border: 1px solid ${props=>props.theme.colors.Grey_3};
    position: relative;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    
    .select-value-wrapper{
        display: flex;
        padding: 6px 12px;
        gap:5px;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color:${props=>props.$variant === 1 ? props.theme.text.Red_2:props.theme.text.Blue_2};
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
    }

    .left-content{
        width: 75%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration-line: underline;

    }
    .right-content{
        height: 100%;
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .options-wrapper{
        position: absolute;
        width: 100%;
        border-radius: 8px;
        background-color: ${props=>props.theme.background.White_2};
        border: 1px solid ${props=>props.theme.colors.Grey_3};
        top:25px;
        padding: 16px;
    }

    .option,.option-midle{
        display: flex;
        padding: 10px 0px;
        justify-content: center;
        background-color: ${props=>props.theme.background.White_2};
        align-items: center;
        color: ${props=>props.theme.text.Grey_4};
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    .option-midle{
        border-top: 1px solid ${props=>props.theme.colors.Grey_3};
        border-bottom: 1px solid ${props=>props.theme.colors.Grey_3};
    }
`;

export const OptionsWrapper = styled.div`
   position: absolute;
    width: 100%;
    border-radius: 8px;
    background-color: ${props=>props.theme.background.White_2};
    border: 1px solid ${props=>props.theme.colors.Grey_3};
    top:25px;
    padding: 16px;
`;

export const Icons =styled.div`
    width: ${props=>props.width};
    height: ${props=>props.height};
    display: flex;
    justify-content: center;
    align-items: center;
 `;   
