import * as Style from "./styles"
import topHeaderImg from '../../assets/imageHeader.svg'
import iconBillsToPay from '../../assets/iconBillsToPay.svg'
import { BillsToPayModal } from "../BillsToPayModal"
import { useEffect, useState } from "react"
import { Modal} from "../Modal"
import { getToken } from "../../settings/Api/services/local_storage_services"
import DashboardServices from "../../settings/Api/services/dashboard_services"
import { Currency, FormateDate, verifyUrlOnPopState } from "../../global/functions"
import { Select } from "../Select"
import { useLocation, useNavigate } from "react-router-dom"

export const BillsToPayCard = (props)=>{
    const[billsToPayInfo,setBillsToPayInfo] = useState([])
    const[billsToPayCompleteList,setBillsToPayCompleteList] = useState([])
    const[billsToPayTotal,setBillsToPayTotal] = useState([])
    const[selectValue,setSelectValue] = useState("Próximos 7 dias")
    const[modalIsOpen, setIsOpen] = useState(false);
    const[openFilter,setOpenFilter] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    const getBillsToPayInfo = async (detalhe,type)=>{
        try {
            const service = new DashboardServices()
            const result = await service.getDashboardBillsToPayInfo({
                cnpj:getToken('CNPJ'),
                tipo:type,
                detalhe:detalhe
            })
            if(result.success === true){
                if(detalhe === "S"){
                    setBillsToPayCompleteList([result.data])
                }else{
                    setBillsToPayInfo([result.data])
                }
            }
            
        } catch (error) {
            
        }
                
    }

    const handleClickPeriod = (value)=>{
        switch (value) {
            case 1:
                getBillsToPayInfo("N","1")
                setSelectValue("Hoje")
                setOpenFilter(false)
            break;
            
            case 2:
                getBillsToPayInfo("N","2")
                setSelectValue("Próximos 7 dias")
                setOpenFilter(false)
            break;

            case 3:
                getBillsToPayInfo("N","3")
                setSelectValue("Próximos 30 dias")
                setOpenFilter(false)

            break;
        
            default:
                break;
        }
    }

    const getBillsToPayTotal = async ()=>{
        try {
            const service = new DashboardServices()
            const result = await service.getDashboardBillsToPayTotal({
                cnpj:getToken('CNPJ'),
            })
            if(result.success === true){
                setBillsToPayTotal([result.data])
            }
            
        } catch (error) {
            
        }
                
    }

    const handleClickSeeMore =()=>{
        navigate('/Dashboard/ContasPagar')
        setIsOpen(true);
        getBillsToPayInfo("S","1");
    }

    const closeModal=()=> {
        navigate(-1);
        setIsOpen(false);
      }



    useEffect(() => {
        verifyUrl();
        getBillsToPayInfo("N",'2')
        getBillsToPayTotal();
    },[]);

    useEffect(() => {
        HandleButtonBack();

    },[location.pathname])
    
     
    const verifyUrl = () => {

        if (window.location.pathname === '/Dashboard/ContasPagar') {
            setIsOpen(true);
            getBillsToPayInfo("S","1");
        }
        else if (window.location.pathname === '/Dashboard') {
            setIsOpen(false);
        }
    
    }

    const HandleButtonBack = () => {
        verifyUrlOnPopState(verifyUrl);
    }



    return(
        <Style.Container id={props.id}>
              <div className='generic-wrapper'>
                <Style.Icons>
                    <img src={iconBillsToPay} alt='ícone uma seta descendo'/>
                </Style.Icons>
            </div>
              <div className='generic-wrapper'>
                <p className='title-card'>Contas a Pagar</p>
            </div>
            <div className='generic-wrapper'>
                <Select
                    openFilter={openFilter}
                    variant={1}
                    OpenSelectOptions={setOpenFilter}
                    selectValue={selectValue}
                    handleClickPeriod={handleClickPeriod}
                />
            </div>
            <div className='value-wrapper'>
                <div className='generic-wrapper'>
                    <p className='text-bold'>{`Total a pagar (${selectValue})`}</p>
                </div>
                <div className='generic-wrapper-values'>
                    <p className='value-general'>{billsToPayInfo.length>0?Currency(billsToPayInfo[0].total):"..."}</p>
                </div>
            </div>
            <div className='items-list-wrapper'>
                <div className="items-list-header">
                    <div className="items-list-header-left">
                        <p>Vencimento</p>
                    </div>
                    <div className="items-list-header-midle">
                        <p>Descrição</p>
                    </div>
                    <div className="items-list-header-right">
                        <p>Total $</p>
                    </div>
                </div>
                {
                    billsToPayInfo.length>0?(
                        billsToPayInfo[0].titulos.length > 0?(
                            billsToPayInfo[0].titulos.map((item,index)=>{
                                return(
                                    <div key={index} className="item-wrapper">
                                        <div className="item-content-left">
                                            <p>{FormateDate(item.dt_vencimento)}</p>
                                        </div>
                                        <div className="item-content-midle">
                                            <p>{item.descricao}</p>
                                        </div>
                                        <div className="item-content-right">
                                            <p>{Currency(item.total)}</p>
                                        </div>
                                    </div>        
                                )
                            })

                        ):(
                            <div className="item-wrapper">
                                <div className="item-content-left">
                                    <p>...</p>
                                </div>
                                <div className="item-content-midle">
                                    <p>...</p>
                                </div>
                                <div className="item-content-right">
                                    <p>...</p>
                                </div>
                            </div>        

                        )
                    ):(
                    <div className="item-wrapper">
                        <div className="item-content-left">
                            <p>...</p>
                        </div>
                        <div className="item-content-midle">
                            <p>...</p>
                        </div>
                        <div className="item-content-right">
                            <p>...</p>
                        </div>
                    </div>        
                    )
                }        
                {/* <div className="item-wrapper">
                    <div className="item-content-left">
                        <p>03/08</p>
                    </div>
                    <div className="item-content-midle">
                        <p>Contas de agua</p>
                    </div>
                    <div className="item-content-right">
                        <p>R$ 25.000,88</p>
                    </div>
                </div>        
                <div className="item-wrapper">
                    <div className="item-content-left">
                        <p>03/08</p>
                    </div>
                    <div className="item-content-midle">
                        <p>Contas de agua</p>
                    </div>
                    <div className="item-content-right">
                        <p>R$ 25.000,88</p>
                    </div>
                </div>        
                <div className="item-wrapper">
                    <div className="item-content-left">
                        <p>03/08</p>
                    </div>
                    <div className="item-content-midle">
                        <p>Contas de agua</p>
                    </div>
                    <div className="item-content-right">
                        <p>R$ 25.000,88</p>
                    </div>
                </div>        
                <div className="item-wrapper">
                    <div className="item-content-left">
                        <p>03/08</p>
                    </div>
                    <div className="item-content-midle">
                        <p>Contas de agua</p>
                    </div>
                    <div className="item-content-right">
                        <p>R$ 25.000,88</p>
                    </div>
                </div>        
                <div className="item-wrapper">
                    <div className="item-content-left">
                        <p>03/08</p>
                    </div>
                    <div className="item-content-midle">
                        <p>Contas de agua</p>
                    </div>
                    <div className="item-content-right">
                        <p>R$ 25.000,88</p>
                    </div>
                </div>        
                <div className="item-wrapper">
                    <div className="item-content-left">
                        <p>03/08</p>
                    </div>
                    <div className="item-content-midle">
                        <p>Contas de agua</p>
                    </div>
                    <div className="item-content-right">
                        <p>R$ 25.000,88</p>
                    </div>
                </div>         */}
            </div>
            <div className='generic-wrapper'>
                <button  onClick={()=>handleClickSeeMore()} className='see-more-button'>Ver mais +</button>
            </div>
            <div className='another-values-wrapper'>
                <div className='top-header-image'>
                    <img src={topHeaderImg} alt='ícone uma forma geomtrica'/>
                </div>
                <div className='another-values-content'>
                    <div className='generic-wrapper'>
                        <p className='text-bold'>Totalizadores</p>
                    </div>
                    <div className='another-values-items'>
                        <p className='text-bold'>Hoje</p>
                        <p className='text-bold'>{billsToPayTotal.length>0?Currency(billsToPayTotal[0].hoje):"..."}</p>
                    </div>
                    <div className='another-values-items'>
                        <p className='text-default'>Vencidas</p>
                        <p className='text-default'>{billsToPayTotal.length>0?Currency(billsToPayTotal[0].vencidas):"..."}</p>
                    </div>
                    <div className='another-values-items'>
                        <p className='text-default'>Semana atual</p>
                        <p className='text-default'>{billsToPayTotal.length>0?Currency(billsToPayTotal[0].semana_atual):"..."}</p>
                    </div>
                    <div className='another-values-items'>
                        <p className='text-default'>Próxima semana</p>
                        <p className='text-default'>{billsToPayTotal.length>0?Currency(billsToPayTotal[0].proxima_semana):"..."}</p>
                    </div>
                    <div className='another-values-items-total'>
                        <p>Total a Pagar</p>
                        <p>{billsToPayTotal.length>0?Currency(billsToPayTotal[0].total):"..."}</p>
                    </div>
                </div>
                {modalIsOpen === true?
                (
                    <Modal id={'1'}  closeModal={closeModal}>
                        
                        <BillsToPayModal
                            closeModal={closeModal}
                            completeList={billsToPayCompleteList}
                            getBillsToPayInfo={getBillsToPayInfo}
                            billsToPayTotal={billsToPayTotal}
                        />

                    </Modal>
                
                ) 
                :(
                    null 
                )
            }
            </div>
            
        </Style.Container>
    )
}