export default {
   
    //Servidor Comum
    // API_USUARIO:'http://25.53.124.126:9001/site/',
    // API_DASHBOARD:'http://default:9001/resultados/', 

//  Servidor AWS
    API_USUARIO:'https://api.sistefood.com.br:443/site/',
    API_DASHBOARD:'https://default:443/resultados/', 

    // API_USUARIO:'http://localhost:9001/site/',
    // API_DASHBOARD:'http://localhost:9001/resultados/', 
}   