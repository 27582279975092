import baseAPI from '../apiBuild/api'
import baseURLs from '../baseURL/base_urls';
import { getToken } from './local_storage_services';

class DashboardServices {
    constructor (){
        baseURLs.API_DASHBOARD = baseURLs.API_DASHBOARD.replace('default',getToken('SERVIDOR'));
        this.api = baseAPI(baseURLs.API_DASHBOARD);
    }

    async getDashboardGeneralInfo(getGeneralInfo) {  
        const result = await this.api.post('dashBoardGerais',  getGeneralInfo );
        return result.data;  
    }

    async getDashboardPaymentFormInfo(getGeneralInfo) {  
        const result = await this.api.post('dashBoardFormaPgto',  getGeneralInfo );
        return result.data;  
    }

    async getDashboardTop5SellsInfo(getGeneralInfo) {  
        const result = await this.api.post('dashBoardTop5VendasProdutos',  getGeneralInfo );
        return result.data;  
    }

    async getDashboardBillsToReceiveInfo(getGeneralInfo) {  
        const result = await this.api.post('listarContasReceber',  getGeneralInfo );
        return result.data;  
    }

    async getDashboardBillsToReceiveTotal(getGeneralInfo) {  
        const result = await this.api.post('totalizadoresContasReceber',  getGeneralInfo );
        return result.data;  
    }

    async getDashboardBillsToPayInfo(getGeneralInfo) {  
        const result = await this.api.post('listarContasPagar',  getGeneralInfo );
        return result.data;  
    }

    async getDashboardBillsToPayTotal(getGeneralInfo) {  
        const result = await this.api.post('totalizadoresContasPagar',  getGeneralInfo );
        return result.data;  
    }
}

export default DashboardServices;