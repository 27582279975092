import React from "react";
// aqui eu faço o roteamento usando o BrowserRouter 
import { 
    BrowserRouter as Router,
    Route,
    Routes 
} from "react-router-dom";

import PrivateRoutes from "../settings/Api/services/private_route_validation";
import {GlobalStyle} from "../global/GlobalStyles"
import { ThemeProvider } from "styled-components";
import{theme} from "../global/theme/" 
import { Dashboard } from "../pages/main";
import { Login } from "../components/Login";

export default function Routess() {
    return(
        <>
        <Router>
            <ThemeProvider theme={theme}>
            <GlobalStyle/>
                <Routes>
                    
                    <Route path="/" element={<Login/>} /> 
                    <Route
                        path="/Dashboard"
                        element={
                            <PrivateRoutes>
                                {<Dashboard/>}
                            </PrivateRoutes>
                        }
                    />

                    <Route
                        path="/Dashboard/ContasReceber"
                        element={
                            <PrivateRoutes>
                                {<Dashboard/>}
                            </PrivateRoutes>
                        }
                    />

                    <Route
                        path="/Dashboard/ContasPagar"
                        element={
                            <PrivateRoutes>
                                {<Dashboard/>}
                            </PrivateRoutes>
                        }
                    />

                    <Route
                        path="/Dashboard/ProdutosMaisVendidos"
                        element={
                            <PrivateRoutes>
                                {<Dashboard/>}
                            </PrivateRoutes>
                        }
                    />



                    
                    
                    
                </Routes>
            </ThemeProvider>
        </Router>
        </>
    )
}

