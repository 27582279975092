export const Currency =(value)=>{
    return parseFloat(value).toLocaleString('pt-br', {style:'currency', currency:'BRL'})
}

export const FormateDate =(string)=>{
    const reverseDate = string.split('-').reverse();
    const formated = `${reverseDate[0]}/${reverseDate[1]}`
    return formated
}

let mousedown = '';
let mouseup   = '';

export const verifyEventClickModal = (event, functions) =>{
    if (event.type === 'mousedown'){
        mousedown = event.target.id;
    }

    if (event.type === 'mouseup'){
        mouseup = event.target.id;

        if(mousedown === mouseup){
            functions(event)

        }
    }
}

let lengthEventPopState = 0;
export const verifyUrlOnPopState = (functions) => {
    lengthEventPopState ++
    if (lengthEventPopState <=3) window.addEventListener('popstate', functions)
}
