import { useState } from "react";
import * as Style from "./styles";
import { Alert } from "react-bootstrap";
import UsuarioService from "../../settings/Api/services/user_service";
import { incriptPassword } from "../../global/md5";
import { useNavigate } from "react-router-dom";
import { entrar } from "../../settings/Api/services/local_storage_services";
import logo from '../../assets/logo.svg'
import iconLogoTipo from '../../assets/iconLogoTipo.svg'
import { VisibleEyesPassword } from "../VisibleEyesPassword";
import { Establishment } from "../Establishment";

const cnpj = "20798514000141";

export const Login = () => {
    const [form, setForm] = useState({email:'', senha:''});
    const [disabled, setDisabled] = useState(false);
    const [passwordType, setPasswordType] = useState('password');
    const [error, setError] = useState('');
    const [color] =  useState('');
    const navigate  = useNavigate();
    const [establishment, setEstablishment] = useState([]);
    const [isOpenModalEstablishment, setIsOpenModalEstablishment] = useState(false);

    const handleInputs = (event) => {

        setForm( {
            ...form, [event.target.name] : event.target.value.trim()
        })
    }


    const verifyInputs = () => {

        if(!form.email){
            setError('Informe o campo email!');
            return false

        }
        else if (!form.senha) {
            setError('Informe o campo senha!');
            return false
        }
        else {
            return true
        }

    }

    const RenderError = () => {
        return(
            <Alert  variant={color  ?  color :  "warning"}>
                {error}
            </Alert>
        )    
    }

    const handleLogin = async (event) => {
        try {
            event.preventDefault();
            const resultVerifyInputs = verifyInputs();
    
            if (!resultVerifyInputs) return false;
            
            setDisabled(true);
            const senhaIncript = incriptPassword(form.senha);
            const Login = new UsuarioService();
            const result  = await Login.getOne({
                cnpj,
                login:form.email,
                senha: senhaIncript
            })

            if (result.success === true) {
                entrar('SERVIDOR', result.data.cnpj[0].url_servidor);
                entrar('CNPJ', result.data.cnpj[0].valor);

                if (result.data.cnpj.length > 1) {
                    setEstablishment(result.data);
                    setIsOpenModalEstablishment(true);
                }
                else navigate('/Dashboard');
            }
            else if (!result.success){
                setDisabled(false);
                setError(result.message);
            }
   
        } catch (error) {
            setDisabled(false);
            console.log(error)
        }
        

    }

    const handleVisibledPassword = () => {

        setPasswordType(passwordType === 'password' ? 'text' : 'password');
    }

    return(
        <Style.ContainerMain>
            <Style.WrapperLogo/>
            
            {
                isOpenModalEstablishment ? (
                    <Establishment 
                        onClose={() => (setIsOpenModalEstablishment(false),setDisabled(false))}
                        DataResult={establishment}
                        id={'Establishment'}
                    />
                )
                :(
                    null
                )
            }

            <Style.WrapperLogin>
                <Style.ContainerFields>
                    <Style.ImagemLog src={logo} alt ='logo'/>

                    <Style.TextLogin>
                        <p>Faça seu Login</p>
                    </Style.TextLogin>


                    <Style.ContainerInputs>
                        <form autoComplete="off" onSubmit={handleLogin}>  
                            <Style.WrapperInput>
                                <Style.WrapperLabelAndInputEmail>
                                    <p>Login</p>
                                    <input
                                        onChange={(event) => handleInputs(event)}
                                        name='email'
                                        value={form.email}
                                        type={'text'}
                                        autoComplete={'off'}
                                    />

                                </Style.WrapperLabelAndInputEmail>
                            </Style.WrapperInput>

                            <Style.WrapperInput>
                                <Style.WrapperLabelAndInputPassword>
                                    
                                    <p>Senha</p>
                                    <div className="container-input-password">
                                        <input
                                            onChange={(event) => handleInputs(event)}
                                            name='senha'
                                            type={passwordType}
                                            value={form.senha}
                                            autoComplete={'off'}

                                        />

                                        <VisibleEyesPassword handleVisibledPassword={handleVisibledPassword}/>  
                                    </div>

                                </Style.WrapperLabelAndInputPassword>

                            </Style.WrapperInput>

                            <Style.ContainerButton>
                                <Style.Button type="submit" disabled={disabled}>
                                    Entrar
                                </Style.Button>
                            </Style.ContainerButton>
                        </form>
       
                        {   
                            error !== '' ? (
                                <Style.ContainerMessage>
                                    <RenderError />
                                </Style.ContainerMessage>   
                            )
                            :(
                                null
                            )
                        }

                    </Style.ContainerInputs>

                </Style.ContainerFields>

                <Style.FooterLogin>
                    <span>Ambiente seguro</span>
                    <img src={iconLogoTipo} alt='logo'/>
                </Style.FooterLogin>
            </Style.WrapperLogin>

        </Style.ContainerMain>
    )
}