import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    gap: 24px;
    background-color: ${props=>props.theme.background.White_2};
    padding: 24px 16px;
    border-radius: 12px;

    .generic-wrapper,.generic-wrapper-values{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .generic-wrapper-values{
        gap:8px
    }

    .value-wrapper{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .title-card{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color:${props=>props.theme.text.Grey_1};
    }

    .text-bold{
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color:${props=>props.theme.text.Grey_1};
    }

    .text-default{
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color:${props=>props.theme.text.Grey_1};
    }

    .value-general{
        color:${props=>props.theme.text.Blue_1};
        font-weight: 600;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .count-total-sells{
        display: flex;
        padding: 4px 16px;
        align-items: center;
        gap: 8px;
        border-radius: 28px;
        background-color:${props=>props.theme.background.Blue_4};
        color:${props=>props.theme.text.Blue_2};
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }
    .items-list-wrapper{
        display: flex;
        flex-direction: column;
        gap:8px;
    }

    .item-general{
        display: flex;
        padding: 8px 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 14px;
        border: solid 1px ${props=>props.theme.colors.Grey_4};
        background: ${props=>props.theme.background.White_1};  
    }
    .left-general-item-content{
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color:${props=>props.theme.text.Grey_1};

    }   

    .icon-background{
        display: flex;
        width: 28px;
        height: 28px;
        padding: 8.75px;
        justify-content: center;
        align-items: center; 
        border-radius: 7px;
        background:${props=>props.theme.background.Blue_4} ;
    }

    .right-general-item-content{
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    .qtd-sells{
        display: flex;
        padding: 2px 8px;
        align-items: center;
        justify-content: center;
        gap: 8px; 
        background: ${props=>props.theme.background.Blue_4};
        color:${props=>props.theme.text.Blue_2};
        border-radius: 30px; 
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;  
    }

    .item-general-value{
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color:${props=>props.theme.text.Blue_2};
    }
    .top-header-image{
        height: 13px;
        width: 100%;
        img{
            width: 100%;
        }
    }
    .another-values-content{
        display: flex;
        flex-direction: column;
        padding: 16px 12px 16px 12px;
        background: ${props=>props.theme.background.Blue_5};
        gap: 16px;
    }

    .another-values-items{
        display: flex;
        justify-content: space-between;
    }
`;

export const Icons =styled.div`
    width: ${props=>props.width};
    height: ${props=>props.height};
    display: flex;
    justify-content: center;
    align-items: center;
`


