import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props=>props.theme.colors.Blue_1};
    .header{
        position: sticky;
        top:0;
        z-index: 1000;
    }
    
    .content-wrapper{
        display: flex;
        padding: 24px 16px 24px 16px;
        background-color: ${props=>props.theme.colors.Blue_1};
        flex-direction: column;
        gap: 24px;
        max-width: 1280px;
        margin: auto;
    }
`;