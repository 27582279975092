export const TOKEN_KEY = "";

// aqui eu vou verificar se meu localStorage esta vazio ou não 
export const isAuthenticated = (TOKEN_KEY ) => {
    return localStorage.getItem(TOKEN_KEY) !== null
};

// aqui eu pego local.stored para mandar para o axios
export const getToken = (Login) => {
    return localStorage.getItem(Login)
};

//  pega o token gerado
export const entrar = (TOKEN_KEY,token) => {
    // aqui ele seta o valor no localStorage
    localStorage.setItem(TOKEN_KEY, token);
}

export const logout = (TOKEN_KEY) => {
    localStorage.removeItem(TOKEN_KEY);
}