import * as Style from './styles';
import iconAcendantArrow from '../../assets/iconAcendantArrow.svg'
import iconTable from '../../assets/iconTable.svg'
import iconDelivery from '../../assets/iconDelivery.svg'
import iconFrontStore from '../../assets/iconFrontStore.svg'
import topHeaderImg from '../../assets/imageHeader.svg'
import { useEffect, useState } from 'react';
import DashboardServices from '../../settings/Api/services/dashboard_services';
import { getToken } from '../../settings/Api/services/local_storage_services';
import { Currency } from '../../global/functions';
import { Select } from '../Select';

export const GeneralResultCard =(props)=>{
    const[generalResults,setGeneralResults] = useState([])
    const[selectValue,setSelectValue] = useState("Hoje")
    const[openFilter,setOpenFilter] = useState(false)

    const getGeneralInfo = async (type)=>{
        try {
            const service = new DashboardServices()
            const result = await service.getDashboardGeneralInfo({
                cnpj:getToken('CNPJ'),
                tipo:type  
            })
            if(result.success === true){
                setGeneralResults([result.data])
            }
            
        } catch (error) {
            
        }
                
    }

    const handleClickPeriod = (value)=>{
        switch (value) {
            case 1:
                getGeneralInfo("1")
                setSelectValue("Hoje")
                setOpenFilter(false)
            break;
            
            case 2:
                getGeneralInfo("2")
                setSelectValue("Próximos 7 dias")
                setOpenFilter(false)
            break;

            case 3:
                getGeneralInfo("3")
                setSelectValue("Próximos 30 dias")
                setOpenFilter(false)

            break;
        
            default:
                break;
        }
    }

    useEffect(() => {
            handleClickPeriod(1)
     },[]);
    
    return(
        <Style.Container id={props.id}>
            <div className='generic-wrapper'>
                <p className='title-card'>Resultados gerais</p>
            </div>

            <div className='generic-wrapper'>
                <Select
                    variant={0}
                    selectValue={selectValue}
                    handleClickPeriod={handleClickPeriod}
                    openFilter={openFilter}
                    OpenSelectOptions={setOpenFilter}
                />
            </div>

            <div className='value-wrapper'>
                <div className='generic-wrapper'>
                    <p className='text-bold'>Total em vendas</p>
                </div>
                <div className='generic-wrapper-values'>
                    <div className='icon-background'>
                        <Style.Icons width={'24px'} heigth={'24px'}>
                            <img src={iconAcendantArrow} alt='ícone de uma seta subindo'/>
                        </Style.Icons>
                    </div>
                    <p className='value-general'>{generalResults.length>0?Currency(generalResults[0].total_geral.valor_total):"..." }</p>
                </div>
                <div className='generic-wrapper'>
                    <div className='count-total-sells'>
                        <p>{`${generalResults.length>0?generalResults[0].total_geral.qtd_total:"..."} vendas`}</p>
                    </div>
                </div>
            </div>

            <div className='items-list-wrapper'>
                <div className='generic-wrapper'>
                    <p className='text-bold'>Total em vendas por módulos</p>
                </div>

                <div className='item-general'>
                    <div className='left-general-item-content'>
                        <div className='icon-background'>
                            <Style.Icons width={'28px'} height={'28px'}>
                                <img src={iconTable}  alt='ícone de uma mesa'/>
                            </Style.Icons>
                        </div>    
                        <p>Mesas</p>
                    </div>
                    <div className='right-general-item-content'>
                        <div className='qtd-sells'>
                            <p>{`Qtd.${generalResults.length>0?generalResults[0].mesa.qtd:"..."}`}</p>
                        </div>
                        <p className='item-general-value'>{generalResults.length>0?Currency(generalResults[0].mesa.valor):"..."}</p>
                    </div>
                </div>

                <div className='item-general'>
                    <div className='left-general-item-content'>
                        <div className='icon-background'>
                            <Style.Icons width={'28px'} height={'28px'}>
                                <img src={iconDelivery} alt='ícone de um capacete'/>
                            </Style.Icons>
                        </div>    
                        <p>Delivery</p>
                    </div>
                    <div className='right-general-item-content'>
                        <div className='qtd-sells'>
                            <p>{`Qtd.${generalResults.length>0?generalResults[0].delivery.qtd:"..."}`}</p>
                        </div>
                        <p className='item-general-value'>{generalResults.length>0?Currency(generalResults[0].delivery.valor):"..."}</p>
                    </div>
                </div>

                <div className='item-general'>
                    <div className='left-general-item-content'>
                        <div className='icon-background'>
                            <Style.Icons width={'28px'} height={'28px'}>
                                <img src={iconFrontStore} alt='ícone de um carrinho de vendas'/>
                            </Style.Icons>
                        </div>    
                        <p>Frente de Loja</p>
                    </div>
                    <div className='right-general-item-content'>
                        <div className='qtd-sells'>
                            <p>{`Qtd.${generalResults.length>0?generalResults[0].venda.qtd:"..."}`}</p>
                        </div>
                        <p className='item-general-value'>{generalResults.length>0?Currency(generalResults[0].venda.valor):"..."}</p>
                    </div>
                </div>

            </div>

            <div className='another-values-wrapper'>
                <div className='top-header-image'>
                    <img src={topHeaderImg} alt='imagem geometrica' />
                </div>

                <div className='another-values-content'>
                    <div className='generic-wrapper'>
                        <p className='text-bold'>Outros Valores</p>
                    </div>
                    <div className='another-values-items'>
                        <p className='text-bold'>Ticket médio</p>
                        <p className='text-bold'>{generalResults.length>0?Currency(generalResults[0].total_geral.ticket_medio):"..."}</p>
                    </div>
                    <div className='another-values-items'>
                        <p className='text-default'>Vendas Canceladas</p>
                        <p className='text-default'>{generalResults.length>0?Currency(generalResults[0].vendas_canceladas):"..."}</p>
                    </div>
                    <div className='another-values-items'>
                        <p className='text-default'>Qtd. Cancelamentos</p>
                        <p className='text-default'>{generalResults.length>0?generalResults[0].qtd_cancelamento:"..."}</p>
                    </div>
                </div>
                
            </div>
        </Style.Container>
    )  
}