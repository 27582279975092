import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    background-color: ${props=>props.theme.background.White_2};
    padding: 24px 16px ;
    border-radius: 12px;
    overflow-y: hidden;
    height: 100%;
    .go-back{
        display: flex;
        gap:8px;
        align-items: center;
        background-color: ${props=>props.theme.background.White_2};
    }
    .main-content{
        display:flex;
        flex-direction: column;
        gap: 24px;
        background-color: ${props=>props.theme.background.White_2};
        padding-top: 20px;
        overflow-y: auto;
    }

    .generic-wrapper,.generic-wrapper-values{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .generic-wrapper-values{
        gap:8px
    }

    .value-wrapper{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .title-card{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        color:${props=>props.theme.text.Grey_1};
    }

    .text-bold{
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color:${props=>props.theme.text.Grey_1};
    }

    .text-default{
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color:${props=>props.theme.text.Grey_1};
    }

    .value-general{
        color:${props=>props.theme.text.Blue_2};
        font-weight: 600;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .count-total-sells{
        display: flex;
        padding: 4px 16px;
        align-items: center;
        gap: 8px;
        border-radius: 28px;
        background-color:${props=>props.theme.background.Blue_4};
        color:${props=>props.theme.text.Blue_2};
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }
    .items-list-wrapper{
        display: flex;
        flex-direction: column;
        gap:8px;
    }

    .items-list-header{
        display: flex;
        justify-content: space-between;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color:${props=>props.theme.text.Grey_3}
    }

    .items-list-header-left{
        display: flex;
        width: 25%;
        justify-content: center;
        align-items: center;
    }

    .items-list-header-right{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;

    }

    .item-wrapper{
        display:flex;
        align-items: center;
        height: 40px;
        justify-content: space-between;
        border-bottom: 1px solid ${props=>props.theme.colors.Grey_6};
    }

    .items-list-header-midle{
        border-left: solid 1px ${props=>props.theme.colors.Grey_4};
        border-right: solid 1px ${props=>props.theme.colors.Grey_4};
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        width: 25%;
    }

    .item-content-left,.item-content-midle,.item-content-right{
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        text-align: center;
        overflow: hidden;
        p{
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        color:${props=>props.theme.text.Grey_1}
    }

    .item-content-left{
        width: 15%;
    }
    .item-content-midle{
        font-weight: 600;
        width: 50%;
        
    }
    .item-content-right{
        color:${props=>props.theme.text.Red_2};
        width: 30%;
    }

    .item-general{
        display: flex;
        padding: 8px 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 14px;
        border: solid 1px ${props=>props.theme.colors.Grey_4};
        background: ${props=>props.theme.background.White_1};  
    }
    .left-general-item-content{
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color:${props=>props.theme.text.Grey_1};

    }   

    .icon-background{
        display: flex;
        width: 28px;
        height: 28px;
        padding: 8.75px;
        justify-content: center;
        align-items: center; 
        border-radius: 7px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        color:${props=>props.theme.text.Grey_2};
        background:${props=>props.theme.background.Blue_4} ;
    }

    .right-general-item-content{
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    .qtd-sells{
        display: flex;
        padding: 2px 8px;
        align-items: center;
        justify-content: center;
        gap: 8px; 
        background: ${props=>props.theme.background.Blue_4};
        color:${props=>props.theme.text.Grey_2};
        border-radius: 30px; 
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;  
    }

    .item-general-value{
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color:${props=>props.theme.text.Blue_2};
    }
    .top-header-image{
        height: 13px;
        width: 100%;
        /* background-color: tomato; */
        /* align-self: stretch;  */
        img{
            width: 100%;
        }
    }
    .another-values-content{
        display: flex;
        /* height: 100%; */
        flex-direction: column;
        padding: 16px 12px 16px 12px;
        background: ${props=>props.theme.background.Blue_5};
        gap: 16px;
    }

    .another-values-items{
        display: flex;
        justify-content: space-between;
    }

    .another-values-items-total{
        display: flex;
        justify-content: space-between;
        padding: 16px 0px 0px 0px;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color:${props=>props.theme.text.Grey_1};
        border-top: 2px dashed ${props=> props.theme.colors.Grey_5};
    }

    .see-more-button{
        text-decoration: underline;
        outline: none;
        height: 34px;
        width: 100%;
        border: 1px solid  ${props=>props.theme.colors.Blue_5};
        border-radius: 8px;
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        align-self: stretch;
        color:${props=>props.theme.text.Blue_2};
        background-color: ${props=>props.theme.background.White_2};
    }
`;

export const Icons =styled.div`
    width: ${props=>props.width};
    height: ${props=>props.height};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;




