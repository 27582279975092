import styled from "styled-components";

export const Container = styled.div`
    position: fixed;
    z-index: 2000;
    height: 100vh;
    width: 100vw;
    left: 0;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:${props=>props.theme.text.Grey_5};
    background-color: rgba(0, 0, 0, 0.3);
    `; 

export const BoxModal = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props=>props.theme.background.White_2};
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 600px) {
        width: 50%;
        height: 50%;
        border-radius: 8px;

      }
`;




