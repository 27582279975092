import * as Style from './styles';
import DashboardServices from '../../settings/Api/services/dashboard_services';
import { getToken } from '../../settings/Api/services/local_storage_services';
import { useEffect, useState } from 'react';
import iconGoBack from '../../assets/iconGoBack.svg'

import { Currency } from '../../global/functions';
import { Select } from '../Select';
export const MostSoldProductsModal = (props) =>{
    const[topProductstResults,setTopProductsResults] = useState([])
    const[openFilter,setOpenFilter] = useState(false)
    const[selectValue,setSelectValue] = useState("Próximos 7 dias")

    const getMostSoldProductstInfo = async (type)=>{
        try {
            const service = new DashboardServices()
            const result = await service.getDashboardTop5SellsInfo({
                cnpj:getToken('CNPJ'),
                tipo:type
            })
            if(result.success === true){
                setTopProductsResults([result.data])
            }
            
        } catch (error) {
            
        }
                
    }

    const handleClickPeriod = (value)=>{
        switch (value) {
            case 1:
                getMostSoldProductstInfo("1")
                setSelectValue("Hoje")
                setOpenFilter(false)
            break;
            
            case 2:
                getMostSoldProductstInfo("2")
                setSelectValue("Próximos 7 dias")
                setOpenFilter(false)
            break;

            case 3:
                getMostSoldProductstInfo("3")
                setSelectValue("Próximos 30 dias")
                setOpenFilter(false)

            break;
        
            default:
                break;
        }
    }

    useEffect(() => {
        getMostSoldProductstInfo()
     },[]);



    return(
        <Style.Container>
            <div onClick={()=>props.closeModal()} className="go-back">
                <Style.Icons width={'24px'} height={'24px'}>
                    <img src={iconGoBack} alt='ícone de voltar'/>
                </Style.Icons>
                <p className='title-card'>Voltar</p>
            </div>
            <div className="main-content">

                <div className='generic-wrapper'>
                    <p className='title-card'>Produtos mais vendidos</p>
                </div>

                <div className='generic-wrapper'>
                    <Select
                        variant={0}
                        selectValue={selectValue}
                        handleClickPeriod={handleClickPeriod}
                        openFilter={openFilter}
                        OpenSelectOptions={setOpenFilter}
                    />
                </div>
                
                <div className='items-list-wrapper'>
                    {
                        topProductstResults.length>0?(
                            topProductstResults[0].map((item,index)=> {
                                return(
                                    <div key={index} className='item-general'>
                                        <div className='left-general-item-content'>
                                            <div className='icon-background'>
                                            <p>{item.sequencia}</p>
                                            </div>    
                                            <p>{item.produto}</p>
                                        </div>
                                        <div className='right-general-item-content'>
                                            <div className='qtd-sells'>
                                                <p>{item.qtd}</p>
                                            </div>
                                            <p className='item-general-value'>{Currency(item.valor)}</p>
                                        </div>
                                    </div>
                                )
                            })

                        ):(
                            <div className='item-general'>
                                <div className='left-general-item-content'>
                                    <div className='icon-background'>
                                        <p>...</p>
                                    </div>    
                                    <p>...</p>
                                </div>
                                <div className='right-general-item-content'>
                                    <div className='qtd-sells'>
                                        <p>...</p>
                                    </div>
                                    <p className='item-general-value'>...</p>
                                </div>
                            </div>
                        )
                    }
                    {/* <div className='item-general'>
                        <div className='left-general-item-content'>
                            <div className='icon-background'>
                            <p>1</p>
                            </div>    
                            <p>chocolate</p>
                        </div>
                        <div className='right-general-item-content'>
                            <div className='qtd-sells'>
                                <p>9</p>
                            </div>
                            <p className='item-general-value'>{Currency(15)}</p>
                        </div>
                    </div>
                    <div className='item-general'>
                        <div className='left-general-item-content'>
                            <div className='icon-background'>
                            <p>1</p>
                            </div>    
                            <p>chocolate</p>
                        </div>
                        <div className='right-general-item-content'>
                            <div className='qtd-sells'>
                                <p>9</p>
                            </div>
                            <p className='item-general-value'>{Currency(15)}</p>
                        </div>
                    </div>
                    <div className='item-general'>
                        <div className='left-general-item-content'>
                            <div className='icon-background'>
                            <p>1</p>
                            </div>    
                            <p>chocolate</p>
                        </div>
                        <div className='right-general-item-content'>
                            <div className='qtd-sells'>
                                <p>9</p>
                            </div>
                            <p className='item-general-value'>{Currency(15)}</p>
                        </div>
                    </div>
                    <div className='item-general'>
                        <div className='left-general-item-content'>
                            <div className='icon-background'>
                            <p>1</p>
                            </div>    
                            <p>chocolate</p>
                        </div>
                        <div className='right-general-item-content'>
                            <div className='qtd-sells'>
                                <p>9</p>
                            </div>
                            <p className='item-general-value'>{Currency(15)}</p>
                        </div>
                    </div>
                    <div className='item-general'>
                        <div className='left-general-item-content'>
                            <div className='icon-background'>
                            <p>1</p>
                            </div>    
                            <p>chocolate</p>
                        </div>
                        <div className='right-general-item-content'>
                            <div className='qtd-sells'>
                                <p>9</p>
                            </div>
                            <p className='item-general-value'>{Currency(15)}</p>
                        </div>
                    </div>
                    <div className='item-general'>
                        <div className='left-general-item-content'>
                            <div className='icon-background'>
                            <p>1</p>
                            </div>    
                            <p>chocolate</p>
                        </div>
                        <div className='right-general-item-content'>
                            <div className='qtd-sells'>
                                <p>9</p>
                            </div>
                            <p className='item-general-value'>{Currency(15)}</p>
                        </div>
                    </div>
                    <div className='item-general'>
                        <div className='left-general-item-content'>
                            <div className='icon-background'>
                            <p>1</p>
                            </div>    
                            <p>chocolate</p>
                        </div>
                        <div className='right-general-item-content'>
                            <div className='qtd-sells'>
                                <p>9</p>
                            </div>
                            <p className='item-general-value'>{Currency(15)}</p>
                        </div>
                    </div>
                    <div className='item-general'>
                        <div className='left-general-item-content'>
                            <div className='icon-background'>
                            <p>1</p>
                            </div>    
                            <p>chocolate</p>
                        </div>
                        <div className='right-general-item-content'>
                            <div className='qtd-sells'>
                                <p>9</p>
                            </div>
                            <p className='item-general-value'>{Currency(15)}</p>
                        </div>
                    </div>
                    <div className='item-general'>
                        <div className='left-general-item-content'>
                            <div className='icon-background'>
                            <p>1</p>
                            </div>    
                            <p>chocolate</p>
                        </div>
                        <div className='right-general-item-content'>
                            <div className='qtd-sells'>
                                <p>9</p>
                            </div>
                            <p className='item-general-value'>{Currency(15)}</p>
                        </div>
                    </div> */}
                </div>
            </div>    
        </Style.Container>
    )
}