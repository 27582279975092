import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: 0;
        font-family: 'Sora', sans-serif;
        ::-webkit-scrollbar {
            width: 8px;          
            background: #E8E8E8;
            border-radius: 100px;
            height: 8px;
        }

        ::-webkit-scrollbar-thumb{
            width: 8px;
            height: 8px;  
            background: #7A7A7A;
            border-radius: 100px;

        }
        
    }

    html,body {
        width: 100%;
        height: 100%;
        overflow: hidden;
        
    }

    button, input{
        font-family: 'Sora', sans-serif;
    }



    #root{
        font-family: 'Sora', sans-serif;
        overflow: auto;
        width: 100%;
        height: 100%;
        scroll-behavior: smooth;
    }

`;