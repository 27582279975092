import * as Style from "./styles"
import topHeaderImg from '../../assets/imageHeader.svg'
import iconBillsToReceive from '../../assets/iconBillsToReceive.svg'
import { useEffect, useState } from "react";
import { Modal} from "../Modal";
import { BillsToReceiveModal } from "../BillsToReceiveModal";
import DashboardServices from "../../settings/Api/services/dashboard_services";
import { getToken } from "../../settings/Api/services/local_storage_services";
import { Currency, FormateDate, verifyUrlOnPopState } from "../../global/functions";
import { Select } from "../Select";
import { useLocation, useNavigate } from "react-router-dom";

export const BillsToReceive = (props)=>{
    const[billsToReceiveInfo,setBillsToReceiveInfo] = useState([])
    const[billsToReceiveTotal,setBillsToReceiveTotal] = useState([])
    const[billsToReceiveCompleteList,setBillsToReceiveCompleteList] = useState([])
    const[selectValue,setSelectValue] = useState("Próximos 7 dias")
    const[modalIsOpen, setIsOpen] = useState(false);
    const[openFilter,setOpenFilter] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    const getBillsToReceiveInfo = async (detalhe,type)=>{
        try {
            const service = new DashboardServices()
            const result = await service.getDashboardBillsToReceiveInfo({
                cnpj:getToken('CNPJ'),
                tipo:type,
                detalhe:detalhe
            })
            if(result.success === true){
                if(detalhe === "S"){
                    setBillsToReceiveCompleteList([result.data])
                }else{
                    setBillsToReceiveInfo([result.data])
                }

            }
            
        } catch (error) {
            
        }
                
    }

    const handleClickPeriod = (value)=>{
        switch (value) {
            case 1:
                getBillsToReceiveInfo("N","1")
                setSelectValue("Hoje")
                setOpenFilter(false)
            break;
            
            case 2:
                getBillsToReceiveInfo("N","2")
                setSelectValue("Próximos 7 dias")
                setOpenFilter(false)
            break;

            case 3:
                getBillsToReceiveInfo("N","3")
                setSelectValue("Próximos 30 dias")
                setOpenFilter(false)

            break;
        
            default:
                break;
        }
    }

    const handleClickSeeMore =()=>{
        navigate('/Dashboard/ContasReceber')
        setIsOpen(true);
        getBillsToReceiveInfo("S","2");
    }

    const getBillsToReceiveTotal = async ()=>{
        try {
            const service = new DashboardServices()
            const result = await service.getDashboardBillsToReceiveTotal({
                cnpj:getToken('CNPJ'),
            })
            if(result.success === true){
                setBillsToReceiveTotal([result.data])
            }
            
        } catch (error) {
            
        }
                
    }

    const closeModal=()=> {
        navigate(-1);
        setIsOpen(false);
    }

    useEffect(() => {
        verifyUrl();
        getBillsToReceiveInfo("N","2")
        getBillsToReceiveTotal();
    },[]);

    useEffect(() => {
        HandleButtonBack();
    },[location.pathname])

    const verifyUrl = () => {
        if (window.location.pathname === '/Dashboard/ContasReceber') {
            setIsOpen(true);
            getBillsToReceiveInfo("S","2");
        }
        else if (window.location.pathname === '/Dashboard') {
            setIsOpen(false);
        }
    }

    const HandleButtonBack = () => {
        verifyUrlOnPopState(verifyUrl);
    }

    return(
        <Style.Container id={props.id}>
              <div className='generic-wrapper'>
                <Style.Icons>
                    <img src={iconBillsToReceive} alt='ícone de uma seta azul'/>
                </Style.Icons>
            </div>
              <div className='generic-wrapper'>
                <p className='title-card'>Contas a Receber</p>
            </div>
            <div className='generic-wrapper'>
                <Select
                    variant={0}
                    openFilter={openFilter}
                    OpenSelectOptions={setOpenFilter}
                    selectValue={selectValue}
                    handleClickPeriod={handleClickPeriod}
                />
            </div>
            <div className='value-wrapper'>
                <div className='generic-wrapper'>
                    <p className='text-bold'>{`Total a receber (${selectValue})`}</p>
                </div>
                <div className='generic-wrapper-values'>
                    <p className='value-general'>{billsToReceiveInfo.length>0?Currency(billsToReceiveInfo[0].total):"..."}</p>
                </div>
            </div>
            <div className='items-list-wrapper'>
                <div className="items-list-header">
                    <div className="items-list-header-left">
                        <p>Vencimento</p>
                    </div>
                    <div className="items-list-header-midle">
                        <p>Descrição</p>
                    </div>
                    <div className="items-list-header-right">
                        <p>Total $</p>
                    </div>
                </div>
                {
                    billsToReceiveInfo.length>0?(
                        billsToReceiveInfo[0].titulos.length>0?(
                            billsToReceiveInfo[0].titulos.map((item,index)=>{
                                return(
                                    <div key={index} className="item-wrapper">
                                        <div className="item-content-left">
                                            <p>{FormateDate(item.dt_vencimento)}</p>
                                        </div>
                                        <div className="item-content-midle">
                                            <p>{item.descricao}</p>
                                        </div>
                                        <div className="item-content-right">
                                            <p>{Currency(item.total)}</p>
                                        </div>
                                    </div>        
    
                                )
                            })

                        ):(
                            <div className="item-wrapper">
                                <div className="item-content-left">
                                    <p>...</p>
                                </div>
                                <div className="item-content-midle">
                                    <p>...</p>
                                </div>
                                <div className="item-content-right">
                                    <p>...</p>
                                </div>
                            </div>        
                        )
                    ):(
                        <div className="item-wrapper">
                            <div className="item-content-left">
                                <p>...</p>
                            </div>
                            <div className="item-content-midle">
                                <p>...</p>
                            </div>
                            <div className="item-content-right">
                                <p>...</p>
                            </div>
                        </div>        
                    )
                }        

            </div>
            <div className='generic-wrapper'>
                <button onClick={()=>handleClickSeeMore()} className='see-more-button'>Ver mais +</button>
            </div>
            <div className='another-values-wrapper'>
                <div className='top-header-image'>
                    <img src={topHeaderImg} alt='ícone de uma forma geométrica'/>
                </div>
                <div className='another-values-content'>
                    <div className='generic-wrapper'>
                        <p className='text-bold'>Totalizadores</p>
                    </div>
                    <div className='another-values-items'>
                        <p className='text-bold'>Hoje</p>
                        <p className='text-bold'>{billsToReceiveTotal.length>0?Currency(billsToReceiveTotal[0].hoje):"..."}</p>
                    </div>
                    <div className='another-values-items'>
                        <p className='text-default'>Vencidas</p>
                        <p className='text-default'>{billsToReceiveTotal.length>0?Currency(billsToReceiveTotal[0].vencidas):"..."}</p>
                    </div>
                    <div className='another-values-items'>
                        <p className='text-default'>Semana atual</p>
                        <p className='text-default'>{billsToReceiveTotal.length>0?Currency(billsToReceiveTotal[0].semana_atual):"..."}</p>
                    </div>
                    <div className='another-values-items'>
                        <p className='text-default'>Próxima semana</p>
                        <p className='text-default'>{billsToReceiveTotal.length>0?Currency(billsToReceiveTotal[0].proxima_semana):"..."}</p>
                    </div>
                    <div className='another-values-items-total'>
                        <p>Total a Receber</p>
                        <p>{billsToReceiveTotal.length>0?Currency(billsToReceiveTotal[0].total):"..."}</p>
                    </div>
            </div>

            </div>
            {modalIsOpen === true?
                (
                    <Modal id={'1'} closeModal={closeModal}>
                        <BillsToReceiveModal
                            closeModal={closeModal}
                            completeList={billsToReceiveCompleteList}
                            getBillsToReceiveInfo={getBillsToReceiveInfo}
                            billsToReceiveTotal={billsToReceiveTotal}
                        />
                    </Modal>
                
                ) 
                :(
                    null 
                )
            }
            
        </Style.Container>
    )
}