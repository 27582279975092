import baseAPI from '../apiBuild/api';
import baseURLs from '../baseURL/base_urls';

class UsuarioService {
    constructor(){
        this.api = baseAPI(baseURLs.API_USUARIO);
    }

    async getOne (modalUser){
        const result = await this.api.post('login', modalUser)
        return result.data;
    }
    
    async add(modalUser) {
        const result = await this.api.post('cadastrarUsuario', modalUser);
        return result.data;
    }
}

export default UsuarioService;