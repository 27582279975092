import * as Style from './styles';
import iconPix from '../../assets/iconPix.svg'
import iconMoney from '../../assets/iconMoney.svg'
import iconPaymentCard from '../../assets/iconPaymentCard.svg'
import iconOthers from '../../assets/iconOthers.svg'
import DashboardServices from '../../settings/Api/services/dashboard_services';
import { getToken } from '../../settings/Api/services/local_storage_services';
import { useEffect, useState } from 'react';
import { Currency } from '../../global/functions';
import { Select } from '../Select';
export const PaymentFormCard =(props)=>{
    const[paymentResults,setPaymentResults] = useState([])
    const[selectValue,setSelectValue] = useState("Hoje")
    const[openFilter,setOpenFilter] = useState(false)

    const getPaymentInfo = async (type)=>{
        try {
            const service = new DashboardServices()
            const result = await service.getDashboardPaymentFormInfo({
                cnpj:getToken('CNPJ'),
                tipo:type  
            })
            if(result.success === true){
                setPaymentResults([result.data])
            }
            
        } catch (error) {
            
        }
                
    }

    const handleClickPeriod = (value)=>{
        switch (value) {
            case 1:
                getPaymentInfo("1")
                setSelectValue("Hoje")
                setOpenFilter(false)
            break;
            
            case 2:
                getPaymentInfo("2")
                setSelectValue("Próximos 7 dias")
                setOpenFilter(false)
            break;

            case 3:
                getPaymentInfo("3")
                setSelectValue("Próximos 30 dias")
                setOpenFilter(false)
            break;
        
            default:
                break;
        }
    }

    useEffect(() => {
            handleClickPeriod(1)
     },[]);

    return(
        <Style.Container id={props.id}>
            <div className='generic-wrapper'>
                <p className='title-card'>Total de vendas por forma de pagamento</p>
            </div>
            <div className='generic-wrapper'>
                <Select
                    variant={0}
                    selectValue={selectValue}
                    handleClickPeriod={handleClickPeriod}
                    openFilter={openFilter}
                    OpenSelectOptions={setOpenFilter}
                />
            </div>
            <div className='items-list-wrapper'>
                <div className='item-general'>
                    <div className='left-general-item-content'>
                        <div className='icon-background'>
                            <Style.Icons width={'28px'} height={'28px'}>
                                <img src={iconPix} alt='ícone do pix'/>
                            </Style.Icons>
                        </div>    
                        <p>Pix</p>
                    </div>
                    <div className='right-general-item-content'>
                        <div className='qtd-sells'>
                            <p>{`Qtd.${paymentResults.length>0?paymentResults[0].pix.qtd:"..."}`}</p>
                        </div>
                        <p className='item-general-value'>{paymentResults.length>0?Currency(paymentResults[0].pix.valor):"..."}</p>
                    </div>
                </div>
                <div className='item-general'>
                    <div className='left-general-item-content'>
                        <div className='icon-background'>
                            <Style.Icons width={'28px'} height={'28px'}>
                                <img src={iconMoney}  alt='ícone de dinheiro'/>
                            </Style.Icons>
                        </div>    
                        <p>Dinheiro</p>
                    </div>
                    <div className='right-general-item-content'>
                        <div className='qtd-sells'>
                            <p>{`Qtd.${paymentResults.length>0?paymentResults[0].dinheiro.qtd:"..."}`}</p>
                        </div>
                        <p className='item-general-value'>{paymentResults.length>0?Currency(paymentResults[0].dinheiro.valor):"..."}</p>
                    </div>
                </div>
                <div className='item-general'>
                    <div className='left-general-item-content'>
                        <div className='icon-background'>
                            <Style.Icons width={'28px'} height={'28px'}>
                                <img src={iconPaymentCard} alt='ícone de cartão'/>
                            </Style.Icons>
                        </div>    
                        <p>Cartões</p>
                    </div>
                    <div className='right-general-item-content'>
                        <div className='qtd-sells'>
                            <p>{`Qtd.${paymentResults.length>0?paymentResults[0].cartao.qtd:"..."}`}</p>
                        </div>
                        <p className='item-general-value'>{paymentResults.length>0?Currency(paymentResults[0].cartao.valor):"..."}</p>
                    </div>
                </div>

                <div className='item-general'>
                    <div className='left-general-item-content'>
                        <div className='icon-background'>
                            <Style.Icons width={'28px'} height={'28px'}>
                                <img src={iconOthers} alt='ícone de três pontos'/>
                            </Style.Icons>
                        </div>    
                        <p>Outros</p>
                    </div>
                    <div className='right-general-item-content'>
                        <div className='qtd-sells'>
                            <p>{`Qtd.${paymentResults.length>0?paymentResults[0].outros.qtd:"..."}`}</p>
                        </div>
                        <p className='item-general-value'>{paymentResults.length>0?Currency(paymentResults[0].outros.valor):"..."}</p>
                    </div>
                </div>
            </div>
             <div className='value-wrapper'>
                <div className='generic-wrapper'>
                    <p className='text-bold'>Total</p>
                </div>
                <div className='generic-wrapper-values'>
                    <p className='value-general'>{paymentResults.length>0?Currency(paymentResults[0].faturamento_total.valor):"..."}</p>
                </div>
                <div className='generic-wrapper'>
                    <div className='count-total-sells'>
                        <p>{`${paymentResults.length>0?paymentResults[0].faturamento_total.qtd:"..."} vendas`}</p>
                    </div>
                </div>
            </div>
        </Style.Container>
    )
}